import './switch.scss';

class Switch {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-switch',
            toggleAttr: 'toggle'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$switch = $element;
        this.$switchToggle = this.$switch.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.toggleAttr + '"]');

        this.initialize();
    }

    initialize () {
        this.setEvents();

        this.$switch.setAttribute('rel', this.$switchToggle[0].value);
    }

    setEvents () {
        this.$switch.addEventListener('change', (e) => {
            const $element = e.target;

            this.$switch.setAttribute('rel', $element.value);
        });
    }
}

export { Switch };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$switch = $context.querySelectorAll('[data-switch="root"]');
        for (let i = 0; i < $$switch.length; i++) {
            const $switch = $$switch[i];

            const switchAPI = new Switch($switch);
            $switch.API = switchAPI;
        }
    }
});
